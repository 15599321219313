import './App.css';
import { Alert, Button, Card, Progress, Segmented, Spin, Statistic, Tag } from 'antd';
import Icon, {ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, PoweroffOutlined, PlayCircleOutlined} from '@ant-design/icons';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, onValue, ref, set, update } from 'firebase/database';
import { auth, database as db } from './firebase';
import { PickerView } from 'antd-mobile';

const { Countdown } = Statistic;

const timePickerColumns = [
  [],
  [
    { label: "00", value: 0 },
    { label: "15", value: 15 },
    { label: "30", value: 30 },
    { label: "45", value: 45 },
  ],
  [
    { label: "AM", value: 'am' },
    { label: "PM", value: 'pm' }
  ]
];
function populateHourPicker() {
  for(let i = 1; i < 13; i++) timePickerColumns[0].push({ label: i.toString(), value: i });
}
populateHourPicker();

function isOnline(updatedAt) {
  return (Date.now() - updatedAt) < 120 * 1000;
}

function statusIcon(online) {
  return online ? <CheckCircleOutlined /> : <CloseCircleOutlined />
}

function SwitchUI() {
  const navigate = useNavigate();
  
  const [sensorStatus, setSensorStatus] = useState({icon: <InfoCircleOutlined />, connected: false, loading: true, reading: 0, rate: 0});
  const [controlStatus, setControlStatus] = useState({icon: <InfoCircleOutlined />, connected: false, loading: true, state: false, timer: 0, disabled: false});
  const [controllerSched, setControllerSched] = useState({ hour: 0, min: 0, am: true });
  const [schedLoading, setSchedLoading] = useState(false);

  const [serverStatus, setServerStatus] = useState("Connecting to the control server");
  
  const [sensorPanel, setSensorPanel] = useState("level");
  const [controlPanel, setControlPanel] = useState("state");
  

  useEffect(() => {
    if(!auth.currentUser) navigate("/login");
    
    const connectionRef = ref(db, ".info/connected");
    onValue(connectionRef, (snap) => {
      if(snap.val()) setServerStatus("Server online");
      else setServerStatus("Disconnected...");
    });

    const sensorRef = ref(db, "/waterLvl");
    get(sensorRef).then((snapshot) => {
      if(snapshot.exists()) {
        const sensorData = snapshot.val();
        if(!sensorData) return;

        console.log(sensorData.updatedAt);
        const online = isOnline(sensorData.updatedAt);
        setSensorStatus({ reading: sensorData.lvl, rate: sensorData.rate, loading: false, connected: online, icon: statusIcon(online) });
      }
    });
    
    const controlRef = ref(db, "/waterPumpStatus");
    get(controlRef).then((snapshot) => {
      if(snapshot.exists()) {
        const controlData = snapshot.val();
        
        const online = isOnline(controlData.updatedAt);
        setControlStatus({ state: controlData.state, timer: controlData.targetTime * 1000, connected: online, loading: false, icon: statusIcon(online), disabled: controlData.disabled });
      }
    });

    const controllerSchedRef = ref(db, "/waterPumpStatus/schedule")
    get(controllerSchedRef).then((snapshot) => {
      if(snapshot.exists()) {
        const schedule = snapshot.val();

        setControllerSched({hour: schedule.hour, min: schedule.min, am: schedule.am });
      }
    });
    
    onValue(sensorRef, (snapshot) => {
      const sensorData = snapshot.val();
      if(!sensorData) return;

      const online = isOnline(sensorData.updatedAt);
      setSensorStatus({ reading: sensorData.lvl, rate: sensorData.rate, loading: false, connected: online, icon: statusIcon(online)});
    });
    
    onValue(controlRef, (snapshot) => {
      const controlData = snapshot.val();
      if(!controlData) return;
      
      const online = isOnline(controlData.updatedAt);
      setControlStatus({ state: controlData.state, timer: controlData.targetTime * 1000, connected: online, loading: false, icon: statusIcon(online), disabled: controlData.disabled });
    
      setControllerSched({
        hour: controlData.schedule.hour, 
        min: controlData.schedule.min, 
        am: controlData.schedule.am 
      });
    })
  }, [navigate]);

  const toggleControl = () => {
    setControlStatus({...controlStatus, loading: true});
    
    const controlRef = ref(db, "/setControl/status");
    const randNum = Math.floor(Math.random() * 1000);
    set(controlRef, (!controlStatus.state)? randNum : -1 * randNum);
  }

  const toggleController = () => {
    setControlStatus({...controlStatus, loading: true});

    const controlRef = ref(db, "/setControl/disabled");
    const randNum = Math.floor(Math.random() * 1000);
    set(controlRef, (!controlStatus.disabled)? randNum : -1 * randNum);
  }

  const changeSchedule = (value) => {
    if(value == null) return;

    setSchedLoading(true);

    const hour = value[0];
    const min = value[1];
    const am = value[2]? true : false;

    const sched = { hour: hour, min: min, am: am };
    setControllerSched(sched);
    
    const controllerSchedRef = ref(db, "/setControl/schedule");
    update(controllerSchedRef, sched).then(() => {
      setSchedLoading(false);
    });
  }

  const levelIndicator = () => {
    return <Progress type="circle" className="level-indicator" size={150} percent={parseInt(sensorStatus.reading)} status={(sensorStatus.connected && !sensorStatus.loading)? "normal" : "exception"}/>
  }
  
  const rateIndicator = () => {
    return (
      <Statistic
      value={sensorStatus.rate}
      className="rate-indicator"
      valueStyle={{
        color: (sensorStatus.rate >= 0)? '#3f8600' : '#cf1322',
      }}
      prefix={(sensorStatus.rate >= 0)? <ArrowUpOutlined /> : <ArrowDownOutlined />}
      suffix="%/min"
      />
    )
  }
  
  const stateIndicator = () => {
    return (
      <Spin spinning={controlStatus.loading} >
        {controlStatus.disabled?
          <>
            <span>
              Controller disabled, click below to resume
            </span> <br/>
            <PlayCircleOutlined className="disableBtn" onClick={toggleController} />
          </>
        : <PoweroffOutlined className="powerBtn" style={{color: controlStatus.state? "#00e384" : "#dc4446"}} onClick={toggleControl}/>
        }
      </Spin>
    )
  }
  
  const timerPanel = () => {
    return (
      controlStatus.disabled?
      <Countdown className="timer" valueStyle={{ color: "gray" }} value={ 0 } format='mm:ss'/>
      :<Countdown className="timer" value={ controlStatus.timer } format='mm:ss'/>
    )
  }

  const schedulePanel = () => {
    const hr = controllerSched.hour;
    const min = controllerSched.min;
    const amVal = (controllerSched.am)? "am" : "pm";
    
    return (
      controlStatus.disabled?
      "Unavailable, please resume the pump operations and try again"
      : <PickerView aria-disabled className="schedule" columns={timePickerColumns} mouseWheel={true} defaultValue={[hr, min, amVal]} onChange={changeSchedule} style={{ '--height': "100%" }} loading={schedLoading}/>
    )
  }
  
  const cardTitle = (title, sensorStatus) => {
    let color = "processing";
    let statusMsg = "connecting...";
    if(sensorStatus.connected || !sensorStatus.loading) {
      color = sensorStatus.connected? "success" : "error";
      statusMsg = sensorStatus.connected? "connected" : "device offline";
    }
    return (
      <div className="card-title">
        <span>{title}</span>
        <Tag icon={sensorStatus.icon} color={color} className="status">
          {statusMsg}
        </Tag>
      </div>
    )
  }
 
  return (
    <div className="dashboard">
      <Alert type="info" message={serverStatus} showIcon={true} style={{width: "100%"}}/>
      <div className="panel">
        <Card title={cardTitle("Water", sensorStatus)} bordered={false} className="sensor-card">
          <Segmented options={["level", "rate"]} block onChange={(val) => setSensorPanel(val)} />
          {(sensorPanel == "level")? levelIndicator() : rateIndicator()}
        </Card>
        <Card title={cardTitle("Pump", controlStatus)} bordered={false} className="control-card">
          <Segmented options={["state", "timer", "schedule"]} block onChange={(val) => setControlPanel(val)} />
          {(controlPanel == "state") ? stateIndicator() : (controlPanel == "timer") ? timerPanel() : schedulePanel()}
        </Card>
      </div>
    </div>
  );
}

export default SwitchUI;